<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Reimbursement details
            <b-spinner v-if="isLoading" small type="grow" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.user_name.id"
                :value="data.user_name"
                :label="controls.user_name.label"
                :readonly="controls.user_name.readonly"
                :mode="mode"
                :required="true"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.track_date.id"
                :value-single="data.track_date"
                :label="controls.track_date.label"
                :readonly="controls.track_date.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-select
                :id="controls.currency.id"
                :ref="controls.currency.id"
                :value="data.currency"
                :label="controls.currency.label"
                :readonly="controls.currency.readonly"
                :options="controls.currency.options"
                :loading="controls.currency.loading"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateCurrency"
                :required="true"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.currency_rate.id"
                :value="data.currency_rate"
                :label="controls.currency_rate.label"
                :readonly="controls.currency_rate.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
          </b-row>
          <h5>Tasting</h5>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.over1case.id"
                :value="data.over1case"
                :label="controls.over1case.label"
                :readonly="controls.over1case.readonly"
                :mode="mode"
                :required="false"
                @changed="updateOver1Case"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.suburban_tasting.id"
                :value="data.suburban_tasting"
                :label="controls.suburban_tasting.label"
                :readonly="controls.suburban_tasting.readonly"
                :mode="mode"
                :required="false"
                @changed="updateSuburbanTasting"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.tasting_additions.id"
                :value="data.tasting_additions"
                :label="
                  `${controls.tasting_additions.label} (${this.data.currency.symbol})`
                "
                :readonly="controls.tasting_additions.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
          </b-row>
          <h5>Automobile Expense</h5>
          <b-row>
            <b-col lg="2" sm="6">
              <inline-input
                :id="controls.tollsandparking.id"
                :value="data.tollsandparking"
                :label="
                  `${controls.tollsandparking.label} (${this.data.currency.symbol})`
                "
                :readonly="controls.tollsandparking.readonly"
                :mode="mode"
                :required="false"
                @changed="updateTollsAndParking"
              />
            </b-col>

            <b-col
              lg="2"
              sm="6"
              v-if="data.currency.id == $constants.CURRENCIES.USD"
            >
              <inline-input
                :id="controls.mileage.id"
                :value="data.mileage"
                :label="controls.mileage.label"
                :readonly="controls.mileage.readonly"
                :mode="mode"
                :required="false"
                @changed="updateMileage"
              />
            </b-col>
            <b-col
              lg="2"
              sm="6"
              v-if="data.currency.id == $constants.CURRENCIES.USD"
            >
              <inline-input
                :id="controls.mileage_rate.id"
                :value="data.mileage_rate"
                :label="controls.mileage_rate.label"
                :readonly="controls.mileage_rate.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
            <b-col
              lg="2"
              sm="6"
              v-if="data.currency.id == $constants.CURRENCIES.USD"
            >
              <inline-input
                :id="controls.mileage_amount.id"
                :value="data.mileage_amount"
                :label="controls.mileage_amount.label"
                :readonly="controls.mileage_amount.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
            <b-col lg="2" sm="6">
              <inline-input
                :id="controls.total_automobile.id"
                :value="data.total_automobile"
                :label="
                  `${controls.total_automobile.label} (${this.data.currency.symbol})`
                "
                :readonly="controls.total_automobile.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>

          <h5>Accommodation and Other</h5>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.expenses.id"
                :value="data.expenses"
                :label="
                  `${controls.expenses.label} (${this.data.currency.symbol})`
                "
                :readonly="controls.expenses.readonly"
                :mode="mode"
                :required="false"
                @changed="updateExpenses"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.accommodation.id"
                :value="data.accommodation"
                :label="
                  `${controls.accommodation.label} (${this.data.currency.symbol})`
                "
                :readonly="controls.accommodation.readonly"
                :mode="mode"
                :required="false"
                @changed="updateAccommodation"
              />
            </b-col>
          </b-row>
          <h5>Totals</h5>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.total_reimbursement.id"
                :value="data.total_reimbursement"
                :label="controls.total_reimbursement.label"
                :readonly="controls.total_reimbursement.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.pay_addition.id"
                :value="data.pay_addition"
                :label="controls.pay_addition.label"
                :readonly="controls.pay_addition.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <inline-input
                :id="controls.notes.id"
                :value="data.notes"
                :label="controls.notes.label"
                :readonly="controls.notes.readonly"
                :is-text-area="true"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>

          <h5>Related documents</h5>
          <hr />
          <files-container
            ref="files"
            :module-id="$route.meta.module.id"
            :entity-id="data.id"
            :readonly="data.reviewed == true"
            @loaded="onFileContainerLoad"
          />
        </form>
        <hr />

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          @save="
            save('tabular').then(response =>
              response
                ? $router.push($store.getters['router/previousRoute'])
                : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Reimbursement submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Reimbursement submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'

import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import InlineDatePicker from '@/components/InlineDatePicker'

import FilesContainer from '@/components/FilesContainer'
import FormSubmissionActions from '@/components/FormSubmissionActions'

import { mapState, mapActions } from 'vuex'
import mileageRateService from '@/services/Reimbursements/mileage-rate.service'

export default {
  name: 'ReimbursementSubmissionForm',
  props: {
    id: { type: [String, Number], default: '' },
    action: { type: String, default: '' }
  },

  components: {
    InlineInput,
    InlineSelect,
    FilesContainer,
    InlineDatePicker,
    FormSubmissionActions
  },
  data: function () {
    return {
      mode: undefined,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,

      controls: {
        tabs: {
          files: {
            title: 'Files'
          }
        },
        buttons: {
          edit: {
            id: 'button:details_edit'
          },
          save: {
            id: 'button:details_save'
          }
        },
        user_name: {
          id: 'input:user_name',
          label: 'User Name',
          changed: false,
          readonly: true
        },
        currency: {
          id: 'select:currency',
          label: 'Currency',
          options: [],
          changed: false,
          readonly: false
        },
        currency_rate: {
          id: 'input:currency_rate',
          label: 'Currency rate',
          changed: false,
          readonly: true
        },

        track_date: {
          id: 'datepicker:track_date',
          label: 'Date',
          changed: false,
          readonly: false
        },
        tasting_additions: {
          id: 'input:tasting_additions',
          label: 'Tasting Additions',
          changed: false,
          readonly: true
        },
        over1case: {
          id: 'input:over1case',
          label: 'Over 1 Case sold (x$10)',
          changed: false,
          readonly: false
        },
        suburban_tasting: {
          id: 'input:suburban_tasting',
          label: 'Suburban Tasting (x$20)',
          changed: false,
          readonly: false
        },
        notes: {
          id: 'input:notes',
          label: 'Notes',
          changed: false,
          readonly: false
        },
        tollsandparking: {
          id: 'input:tollsandparking',
          label: 'Tolls and parking',
          changed: false,
          readonly: false
        },
        mileage: {
          id: 'input:mileage',
          label: 'Mileage',
          changed: false,
          readonly: false
        },
        mileage_rate: {
          id: 'input:mileage_rate',
          label: 'Mileage Rate ($)',
          changed: false,
          readonly: true
        },
        mileage_amount: {
          id: 'input:mileage_amount',
          label: 'Mileage ($)',
          changed: false,
          readonly: true
        },
        total_automobile: {
          id: 'input:total_automobile',
          label: 'Total Automobile',
          changed: false,
          readonly: true
        },
        expenses: {
          id: 'input:expenses',
          label: 'Expenses',
          changed: false,
          readonly: false
        },
        accommodation: {
          id: 'input:accommodation',
          label: 'Accommodation',
          changed: false,
          readonly: false
        },
        total_reimbursement: {
          id: 'input:total_reimbursement',
          label: 'Total reimbursement ($)',
          changed: false,
          readonly: true
        },
        pay_addition: {
          id: 'input:pay_addition',
          label: 'Total pay addition ($)',
          changed: false,
          readonly: true
        }
      },
      data: {
        id: '',
        user_name: '',
        currency: {
          id: '',
          label: ''
        },
        currency_rate: 1,
        track_date: '',
        over1case: 0,
        suburban_tasting: 0,
        tasting_additions: 0,
        notes: '',
        tollsandparking: 0,
        mileage: 0,
        //DK, 20240821: mileage_rate fetched from the server
        //mileage_rate: 0.67,
        mileage_rate: null,
        mileage_amount: 0,
        total_automobile: 0,
        expenses: 0,
        accommodation: 0,
        total_reimbursement: 0,
        pay_addition: 0
      }
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created: function () {
    this.initialize()
  },
  mounted: async function () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    },
    initialize: async function () {
      let self = this

      this.data.id = this.id

      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
        this.data.id = await this.$api.get('entities/nextid')
      }

      if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      }
      if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      }

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        self.fetchData(this.id)
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.track_date = moment.utc().format('YYYY-MM-DD')

        this.data.user_name =
          this.profile.data.first_name + ' ' + this.profile.data.last_name

        this.updateCurrencyDropdown()

        this.updateMileageRate()
      }
    },
    fetchData: async function (id) {
      let self = this

      return this.$api
        .get(`reimbursements/${id}`)
        .then(response => {
          if (self.$_.isEmpty(response)) return

          self.data.currency_rate = response['currency_rate']

          self.data.id = self.id
          self.data.user_name = response['user_name']
          self.data.track_date = response['track_date']
          self.data.notes = response['notes']
          self.data.over1case = response['rmbadditions_overone']
          self.data.suburban_tasting = response['rmbadditions_suburban']
          self.data.tasting_additions = response['rmbadditions']
          self.data.tollsandparking = response['rmbtollsparking']
          self.data.mileage = response['rmbmileage']
          //self.data.mileage_rate = response['rmbmileagerate']
          self.data.mileage_amount = response['rmbmileagecalc']
          self.data.total_automobile = response['rmbtotalauto']
          self.data.expenses = response['rmbexpenses']
          self.data.accommodation = response['rmbaccommodation']
          self.data.total_reimbursement = response['reimbursementtotal']
          self.data.pay_addition = response['reimbursementpayaddition']

          self.updateCurrencyDropdown(response['currency_id'])

          self.updateMileageRate()
        })
        .catch(error => {
          console.log(error)

          self.$form.makeToastError(error.message)
        })
    },
    edit () {},

    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let self = this
      let method = 'put'
      let url = `reimbursements/${self.data.id}`

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = 'put'
        url = 'reimbursements'
      }

      this.isLoading = true

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          self.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.isLoading = false
          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    async updateMileageRate () {
      let res = await mileageRateService.fetchMileageRateOnDate({
        actualDate: this.data.track_date
          ? this.data.track_date
          : moment().format('YYYY-MM-DD')
      })
      this.data.mileage_rate = res.rate
      this.controls.mileage_rate.label = `Mileage Rate ($) [${res.date}]`
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode)
    },
    updateField (_field, _value, _mode) {
      let payload = {
        id: this.id,
        self: this,
        url: 'reimbursements',
        field: _field,
        value: _value,
        mode: _mode
      }
      this.$form.updateField2(payload)
    },
    updateCurrency (id, value) {
      //this.updateField(id, value);

      this.data.currency = value
      this.data.currency_rate = value.rate

      this.updateTotals()
    },
    updateOver1Case (_field, _value) {
      this.data[_field] = parseFloat(_value) > 0 ? _value : 0

      this.updateTotals()
    },
    updateSuburbanTasting (_field, _value) {
      this.data[_field] = parseFloat(_value) > 0 ? _value : 0

      this.updateTotals()
    },
    updateTollsAndParking (_field, _value) {
      this.data[_field] = parseFloat(_value) > 0 ? _value : 0

      this.updateTotals()
    },
    updateMileage (_field, _value) {
      this.data[_field] = parseFloat(_value) > 0 ? _value : 0

      this.updateTotals()
    },
    updateExpenses (_field, _value) {
      this.data[_field] = parseFloat(_value) > 0 ? _value : 0

      this.updateTotals()
    },
    updateAccommodation (_field, _value) {
      this.data[_field] = parseFloat(_value) > 0 ? _value : 0

      this.updateTotals()
    },
    updateTotals () {
      this.data.tasting_additions = (
        parseFloat(this.data.over1case) * 10 * this.data.currency.rate +
        parseFloat(this.data.suburban_tasting) * 20 * this.data.currency.rate
      ).toFixed(2)

      this.data.mileage_amount = (
        parseFloat(this.data.mileage) * parseFloat(this.data.mileage_rate)
      ).toFixed(2)

      this.data.total_automobile =
        parseFloat(this.data.tollsandparking) +
        parseFloat(this.data.mileage_amount)

      this.data.total_reimbursement =
        //tolls and parking can be submitted at abroad but mileage does not. So tolls and parking can be divided to currency rate if selected currency differs from USD
        (
          parseFloat(this.data.total_automobile) /
            (this.data.currency.id !== this.$constants.CURRENCIES.USD
              ? this.data.currency.rate
              : 1) +
          parseFloat(this.data.expenses) / this.data.currency.rate +
          parseFloat(this.data.accommodation) / this.data.currency.rate
        ).toFixed(2)

      this.data.pay_addition = (
        parseFloat(this.data.tasting_additions) / this.data.currency.rate
      ).toFixed(2)

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.save(this.mode)
      }
    },
    updateCurrencyDropdown (currency_id) {
      let self = this

      this.$api
        .post('dictionaries/currencies', {
          date: self.data.track_date
        })
        .then(response => {
          self.controls.currency.options = response.map(item => {
            return {
              id: item.id,
              label: item.name,
              code: item.code,
              symbol: item.symbol,
              rate: parseFloat(item.rate),
              rate_date: item.rate_date
            }
          })

          //select currency or USD by default
          self.data.currency = self.controls.currency.options.find(
            o =>
              o.id ==
              (currency_id ? currency_id : self.$constants.CURRENCIES.USD)
          )

          console.log(self.data.currency)
          //self.updateTotals()
        })
    }
  },
  watch: {}
}
</script>

<style></style>
