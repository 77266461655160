var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('form', [_c('h5', [_vm._v(" Reimbursement details "), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.user_name.id,
      "value": _vm.data.user_name,
      "label": _vm.controls.user_name.label,
      "readonly": _vm.controls.user_name.readonly,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.track_date.id,
      "value-single": _vm.data.track_date,
      "label": _vm.controls.track_date.label,
      "readonly": _vm.controls.track_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-select', {
    ref: _vm.controls.currency.id,
    attrs: {
      "id": _vm.controls.currency.id,
      "value": _vm.data.currency,
      "label": _vm.controls.currency.label,
      "readonly": _vm.controls.currency.readonly,
      "options": _vm.controls.currency.options,
      "loading": _vm.controls.currency.loading,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateCurrency
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.currency_rate.id,
      "value": _vm.data.currency_rate,
      "label": _vm.controls.currency_rate.label,
      "readonly": _vm.controls.currency_rate.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1)], 1), _c('h5', [_vm._v("Tasting")]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.over1case.id,
      "value": _vm.data.over1case,
      "label": _vm.controls.over1case.label,
      "readonly": _vm.controls.over1case.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateOver1Case
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.suburban_tasting.id,
      "value": _vm.data.suburban_tasting,
      "label": _vm.controls.suburban_tasting.label,
      "readonly": _vm.controls.suburban_tasting.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSuburbanTasting
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tasting_additions.id,
      "value": _vm.data.tasting_additions,
      "label": "".concat(_vm.controls.tasting_additions.label, " (").concat(this.data.currency.symbol, ")"),
      "readonly": _vm.controls.tasting_additions.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1)], 1), _c('h5', [_vm._v("Automobile Expense")]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "2",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tollsandparking.id,
      "value": _vm.data.tollsandparking,
      "label": "".concat(_vm.controls.tollsandparking.label, " (").concat(this.data.currency.symbol, ")"),
      "readonly": _vm.controls.tollsandparking.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateTollsAndParking
    }
  })], 1), _vm.data.currency.id == _vm.$constants.CURRENCIES.USD ? _c('b-col', {
    attrs: {
      "lg": "2",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.mileage.id,
      "value": _vm.data.mileage,
      "label": _vm.controls.mileage.label,
      "readonly": _vm.controls.mileage.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateMileage
    }
  })], 1) : _vm._e(), _vm.data.currency.id == _vm.$constants.CURRENCIES.USD ? _c('b-col', {
    attrs: {
      "lg": "2",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.mileage_rate.id,
      "value": _vm.data.mileage_rate,
      "label": _vm.controls.mileage_rate.label,
      "readonly": _vm.controls.mileage_rate.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1) : _vm._e(), _vm.data.currency.id == _vm.$constants.CURRENCIES.USD ? _c('b-col', {
    attrs: {
      "lg": "2",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.mileage_amount.id,
      "value": _vm.data.mileage_amount,
      "label": _vm.controls.mileage_amount.label,
      "readonly": _vm.controls.mileage_amount.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "2",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.total_automobile.id,
      "value": _vm.data.total_automobile,
      "label": "".concat(_vm.controls.total_automobile.label, " (").concat(this.data.currency.symbol, ")"),
      "readonly": _vm.controls.total_automobile.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('h5', [_vm._v("Accommodation and Other")]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.expenses.id,
      "value": _vm.data.expenses,
      "label": "".concat(_vm.controls.expenses.label, " (").concat(this.data.currency.symbol, ")"),
      "readonly": _vm.controls.expenses.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateExpenses
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.accommodation.id,
      "value": _vm.data.accommodation,
      "label": "".concat(_vm.controls.accommodation.label, " (").concat(this.data.currency.symbol, ")"),
      "readonly": _vm.controls.accommodation.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateAccommodation
    }
  })], 1)], 1), _c('h5', [_vm._v("Totals")]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.total_reimbursement.id,
      "value": _vm.data.total_reimbursement,
      "label": _vm.controls.total_reimbursement.label,
      "readonly": _vm.controls.total_reimbursement.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.pay_addition.id,
      "value": _vm.data.pay_addition,
      "label": _vm.controls.pay_addition.label,
      "readonly": _vm.controls.pay_addition.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('inline-input', {
    attrs: {
      "id": _vm.controls.notes.id,
      "value": _vm.data.notes,
      "label": _vm.controls.notes.label,
      "readonly": _vm.controls.notes.readonly,
      "is-text-area": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('h5', [_vm._v("Related documents")]), _c('hr'), _c('files-container', {
    ref: "files",
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.data.id,
      "readonly": _vm.data.reviewed == true
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      }
    },
    on: {
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push(_vm.$store.getters['router/previousRoute']) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Reimbursement submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Reimbursement submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }